import { motion, useAnimation, useInView } from 'framer-motion'
import { ArrowRight, AtSign } from 'lucide-react'
import { useEffect, useId, useRef, useState } from 'react'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import Marquee from '@/components/ui/marquee'
import { ctaTiles } from '@/config/landing'
import { Highlight } from '../ui/hero-highlight'
import TextShimmer from '../ui/text-shimmer'
import { ModalTrigger } from '../ui/animated-modal'
import ContactUsModal from '../contact-us'
import { ConfettiButton } from '../magicui/confetti'

function shuffleArray(array: any[]) {
  let currentIndex = array.length
  let randomIndex
  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }
  return array
}

function Card(card: { icon: any; bg: string }) {
  const id = useId()
  const controls = useAnimation()
  const ref = useRef(null)
  const inView = useInView(ref, { once: true })

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        transition: { delay: Math.random() * 2, ease: 'easeOut', duration: 1 }
      })
    }
  }, [controls, inView])

  return (
    <motion.div
      key={id}
      ref={ref}
      initial={{ opacity: 0 }}
      animate={controls}
      className={cn(
        'relative size-20 cursor-pointer overflow-hidden rounded-2xl border p-4',
        // light styles
        'bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]'
      )}>
      <card.icon className="size-full text-slate-400" />
      <div className={card.bg}></div>
    </motion.div>
  )
}

export default function CTASection() {
  const [randomTiles1, setRandomTiles1] = useState<typeof ctaTiles>([])
  const [randomTiles2, setRandomTiles2] = useState<typeof ctaTiles>([])
  const [randomTiles3, setRandomTiles3] = useState<typeof ctaTiles>([])
  const [randomTiles4, setRandomTiles4] = useState<typeof ctaTiles>([])

  const ref = useRef(null)
  const inView = useInView(ref, { once: true })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Ensures this runs client-side
      setRandomTiles1(shuffleArray([...ctaTiles]))
      setRandomTiles2(shuffleArray([...ctaTiles]))
      setRandomTiles3(shuffleArray([...ctaTiles]))
      setRandomTiles4(shuffleArray([...ctaTiles]))
    }
  }, [])

  return (
    <ContactUsModal>
      <section id="cta">
        <div className="flex w-full flex-col items-center justify-center">
          <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">
            <Marquee
              reverse
              className="-delay-[200ms] [--duration:20s]"
              repeat={5}>
              {randomTiles1.map((review, idx) => (
                <Card key={idx} {...review} />
              ))}
            </Marquee>
            <Marquee className="[--duration:20s]" repeat={5}>
              {randomTiles2.map((review, idx) => (
                <Card key={idx} {...review} />
              ))}
            </Marquee>
            <Marquee
              reverse
              className="-delay-[200ms] [--duration:20s]"
              repeat={5}>
              {randomTiles3.map((review, idx) => (
                <Card key={idx} {...review} />
              ))}
            </Marquee>
            <Marquee reverse className="[--duration:30s]" repeat={5}>
              {randomTiles4.map((review, idx) => (
                <Card key={idx} {...review} />
              ))}
            </Marquee>
            <div className="absolute z-10" ref={ref}>
              <div className="-mt-6 max-w-[85%] mx-auto rounded-[2rem] border-2 bg-white/10 py-6 px-12 md:py-8 md:px-14 lg:py-12 lg:px-28 shadow-lg backdrop-blur-2xl">
                <div className="flex flex-col gap-2 text-center justify-center items-center">
                  <h1 className="text-lg text-slate-800 italic md:text-2xl font-semibold uppercase tracking-tight">
                    Ügyfélszolgálat
                  </h1>

                  {inView && (
                    <Highlight className="text-slate-800 text-lg md:text-xl w-max font-semibold text-center">
                      egyszerűen, olcsón, gyorsan
                    </Highlight>
                  )}
                </div>

                <p className="mt-6 text-center text-slate-700">
                  <b>24 órán</b> belül elkészítjük a saját <b>ingyenes</b>{' '}
                  chatbotodat.
                </p>

                <div className="mt-8 flex flex-col sm:flex-row items-center justify-center w-full gap-2">
                  <ModalTrigger className="order-2 sm:order-1 p-0 w-full text-slate-700 flex items-center gap-2">
                    <Button
                      id="contact-us-trigger"
                      variant="outline"
                      className="w-full">
                      Kérdésed van? <AtSign className="size-4 ml-2" />
                    </Button>
                  </ModalTrigger>

                  <Button
                    className="order-1 gap-1 w-full rounded-lg text-white bg-gradient-to-r from-indigo-500 to-sky-500 hover:drop-shadow-sm"
                    onClick={() => {
                      const button = document.querySelector(
                        '#hero-cta-button'
                      ) as HTMLButtonElement
                      button?.click()

                      document.cookie = 'chatbot-request-from=footer'
                    }}>
                    <ConfettiButton className="bg-transparent hover:bg-transparent">
                      <TextShimmer className="text-white font-semibold">
                        Ingyenes demó
                      </TextShimmer>
                    </ConfettiButton>
                    <ArrowRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
                  </Button>

                  {/* <a
                  href="#"
                  className={cn(
                    buttonVariants({
                      size: "lg",
                      variant: "outline",
                    }),
                    "hover:text-white group rounded-[2rem] px-6",
                    "bg-gradient-to-r from-indigo-500 to-sky-500 text-white",
                  )}
                >
                  Kérem a demót
                  <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
                </a> */}
                </div>
              </div>

              <div className="bg-backtround absolute inset-0  -z-10 rounded-full opacity-40 blur-xl" />
            </div>
            <div className="to-background absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-70%" />
          </div>
        </div>
      </section>
    </ContactUsModal>
  )
}
